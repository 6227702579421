import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  FormHelperText,
  FormControlLabel,
  Box,
  Tab,
  Tabs,
  AppBar,
  Typography,
  Grid,
  TextField,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormControl,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  Divider,
  Autocomplete,
  DialogContentText,
  DialogActions,
  Zoom,
} from "@mui/material";
import PropTypes from "prop-types";
import unsplash from "../../api/unsplash";
import moment from "moment";
import momentTimezone from "moment-timezone";
import "moment/locale/it";
import { LocalizationProvider,DatePicker,PickersDay,TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment as DateAdapter} from '@mui/x-date-pickers/AdapterMoment'
import ProtocolloSanitarioLavoratore from "./ProtocolloSanitarioLavoratore";
import VisiteMedicheLavoratore from "./VisiteMedicheLavoratore";
import Allegati from "../Allegati";
import CorsiLavoratore from "./CorsiLavoratore";
import {
  Check,
  ArrowBack,
  Edit,
  Flight,
  AirplanemodeInactive,
  Close,
} from "@mui/icons-material";
import GlobalSnackbar from "../Snackbar";
import { AddestramentoLavoratori } from "./AddestramentoLavoratori";
import { StampaDPI } from "./StampaDPI";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const DettaglioLavoratore = (props) => {
  const [open, setOpen] = useState(false);
  const [clienteDistaccato, setClienteDistaccato] = useState(null);
  const [value, setValue] = useState(0);
  const [clienti, setClienti] = useState();
  const [lavoratore, setLavoratore] = useState(props.lavoratore);
  const [lavoratoreCopy, setLavoratoreCopy] = useState(props.lavoratore);
  const [sedi, setSedi] = useState([]);
  const [dettaglioMedicina, setDettaglioMedicina] = useState([]);
  const [mansioni, setMansioni] = useState([]);
  const [mansioniSicurezza, setMansioniSicurezza] = useState([]);
  const [validation, setValidation] = useState({
    DataAssunzione: false,
    Nome: false,
    Cognome: false,
    Mansione: false,
  });

  const fetchData = async () => {
    let responseSedi = await unsplash.get("sedi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.lavoratore.Cliente },
    });
    const SediSenzaCantiere = responseSedi.data.data.filter(
      (elm) => elm.Cantiere === 0
    );
    setSedi(SediSenzaCantiere);

    let responseDettaglioMedicina = await unsplash.get("dettaglioMedicina", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.lavoratore.Sede },
    });

    setDettaglioMedicina(responseDettaglioMedicina.data.data);

    let responseMansioni = await unsplash.get("mansioni", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.lavoratore.Cliente },
    });

    setMansioni(responseMansioni.data.data);

    let responseClienti = await unsplash.get("clienti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    setClienti(responseClienti.data.data);

    let cliDistaccato = responseClienti.data.data.filter(
      (r) => r.ID.toString() === props.lavoratore.Distaccato
    );

    setClienteDistaccato(cliDistaccato ? cliDistaccato[0] : null);

    let responseMansioniSicurezza = await unsplash.get("mansioniSicurezza", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.cliente, sede: props.lavoratore.Sede },
    });
    setMansioniSicurezza(responseMansioniSicurezza.data.data);
  };

  const handleClickOpen = () => {
    setOpen(true);
    fetchData();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  /*useEffect(() => {
    // è un loop qui carico i dati

    const fetchData = async () => {
    
      let responseSedi = await unsplash.get("sedi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.lavoratore.Cliente },
      });
      const SediSenzaCantiere = responseSedi.data.data.filter(
        (elm) => elm.Cantiere === 0
      );
      setSedi(SediSenzaCantiere);

      let responseDettaglioMedicina = await unsplash.get("dettaglioMedicina", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.lavoratore.Sede },
      });

      setDettaglioMedicina(responseDettaglioMedicina.data.data);

      let responseMansioni = await unsplash.get("mansioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.lavoratore.Cliente },
      });

      setMansioni(responseMansioni.data.data);

      let responseClienti = await unsplash.get("clienti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setClienti(responseClienti.data.data);

      let cliDistaccato = responseClienti.data.data.filter(
        (r) => r.ID.toString() === props.lavoratore.Distaccato
      );

      setClienteDistaccato(cliDistaccato ? cliDistaccato[0] : null);

      let responseMansioniSicurezza = await unsplash.get("mansioniSicurezza", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.cliente, sede: props.lavoratore.Sede },
      });
      setMansioniSicurezza(responseMansioniSicurezza.data.data);
    };

    fetchData();
  }, []); */

  const handleChange = (e) => {
    let lav = { ...lavoratore };
    lav[e.target.name] = e.target.value;
    setLavoratore(lav);
  };

  const handleSave = async () => {
    try {
      let l = {
        ...lavoratore,
        DataDiNascita: lavoratore.DataDiNascita
          ? moment(lavoratore.DataDiNascita).format("YYYY-MM-DD")
          : null,
        DataAssunzione: lavoratore.DataAssunzione
          ? moment(lavoratore.DataAssunzione).format("YYYY-MM-DD")
          : null,
        DataTermine: lavoratore.DataTermine
          ? moment(lavoratore.DataTermine).format("YYYY-MM-DD")
          : null,
        DataUltimaVisita: lavoratore.DataUltimaVisita
          ? moment(lavoratore.DataUltimaVisita).format("YYYY-MM-DD")
          : null,
        DataInserimento: lavoratore.DataInserimento
          ? moment(lavoratore.DataInserimento).format("YYYY-MM-DD")
          : null,
      };
      delete l.Accertamenti;

      await unsplash.put(
        "lavoratori",
        { lavoratore: l },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      await unsplash.post(
        "storico",
        {
          tabella: "Lavoratori",
          key: lavoratore.ID,
          utente: sessionStorage.getItem("username"),
          after: l,
          before: lavoratoreCopy,
          operazione: "Modifica",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      props.loadLavoratori();
      setLavoratoreCopy(lavoratore);

      setSnack({
        severity: "success",
        messaggio: "Lavoratore modificato con successo",
      });
      setOpenSnack(true);
    } catch {
      setSnack({ severity: "error", messaggio: "Errore durante la creazione" });
      setOpenSnack(true);
    }
    setOpen(false);
  };

  const loadDettaglioMedicina = async (sede) => {
    let responseDettaglioMedicina = await unsplash.get("dettaglioMedicina", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: sede },
    });

    setDettaglioMedicina(responseDettaglioMedicina.data.data);
  };

  const loadSedi = async (cliente) => {
    let responseSedi = await unsplash.get("sedi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: cliente },
    });
    const SediSenzaCantiere = responseSedi.data.data.filter(
      (elm) => elm.Cantiere === 0
    );
    setSedi(SediSenzaCantiere);
  };

  const loadMansioneSicurezza = async (idSede) => {
    let responseMansioniSicurezza = await unsplash.get("mansioniSicurezza", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.cliente, sede: idSede },
    });
    setMansioniSicurezza(responseMansioniSicurezza.data.data);
  };

  return (
    <>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>

      <Dialog
        fullWidth
        maxWidth="2xl"
        sx={{ minHeight: "120vh", maxHeight: "120vh", my: "auto" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <GlobalSnackbar
          severity={snack.severity}
          messaggio={snack.messaggio}
          openSnack={openSnack}
          handleCloseSnack={handleCloseSnack}
        />
        <Grid item xs={12} style={{ width: "100%" }}>
          <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
            <Card sx={{ position: "relative", my: "auto", boxShadow: "none" }}>
              <CardHeader
                sx={{ textAlign: "center" }}
                title={lavoratoreCopy.Nome + " " + lavoratoreCopy.Cognome}
                titleTypographyProps={{ variant: "h4" }}
              />
            </Card>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Tabs
                value={value}
                textColor="inherit"
                variant="scrollable"
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label="Generali" {...a11yProps(0)} />
                <Tab label="Protocollo Sanitario" {...a11yProps(1)} />
                <Tab label="Visite" {...a11yProps(2)} />
                <Tab label="Corsi" {...a11yProps(3)} />
                <Tab label="Allegati" {...a11yProps(4)} />
                <Tab label="Addestramento" {...a11yProps(5)} />
                <Tab label="DPI" {...a11yProps(5)} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Box sx={{ maxHeight: "60vh" }}>
                {lavoratore ? (
                  <Grid container xs={12} spacing={2}>
                    <Grid item xs={6} md={4}>
                      <FormControl
                        fullWidth
                        required
                        error={validation.Mansione}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Cliente
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          value={lavoratore.Cliente}
                          onChange={(e) => {
                            let l = { ...lavoratore };
                            l.Cliente = e.target.value;
                            l.Sede = null;
                            l.DettaglioMedicina = null;
                            setLavoratore(l);
                            loadSedi(e.target.value);
                          }}
                          input={<OutlinedInput label="Cliente" />}
                        >
                          {clienti?.map((cli) => (
                            <MenuItem key={cli.ID} value={cli.ID}>
                              {cli.RagioneSociale}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText
                          style={{
                            display: validation.Mansione ? "" : "None",
                          }}
                        >
                          Cliente obbligatorio
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-multiple-name-label">
                          Sede
                        </InputLabel>
                        <Select
                          value={lavoratore.Sede}
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          onChange={(e) => {
                            let l = { ...lavoratore };
                            l.Sede = e.target.value;
                            l.DettaglioMedicina = null;
                            setLavoratore(l);
                            loadDettaglioMedicina(e.target.value);
                            loadMansioneSicurezza(e.target.value);
                          }}
                          input={<OutlinedInput label="Sede" />}
                        >
                          {sedi?.map((sede) => (
                            <MenuItem key={sede.ID} value={sede.ID}>
                              {sede.Descrizione}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-multiple-name-label">
                          Dettaglio Medicina
                        </InputLabel>
                        <Select
                          value={lavoratore.DettaglioMedicina}
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          onChange={(e) => {
                            let l = { ...lavoratore };
                            l.DettaglioMedicina = e.target.value;
                            setLavoratore(l);
                          }}
                          input={<OutlinedInput label="Dettaglio Medicina" />}
                        >
                          {dettaglioMedicina?.map((d) => {
                            let lastValue =
                              props.lavoratore?.Gruppo === "Conter"
                                ? d.Referente
                                : d.NomeMedico;
                            return (
                              <MenuItem key={d.ID} value={d.ID}>
                                {d.Blocco +
                                  " - " +
                                  d.Categoria +
                                  " - " +
                                  lastValue}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <FormControl
                        required
                        fullWidth
                        error={validation.Mansione}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Mansione
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          value={lavoratore.Mansione}
                          onChange={(e) => {
                            let l = { ...lavoratore };
                            l.Mansione = e.target.value;
                            setLavoratore(l);
                          }}
                          input={<OutlinedInput label="Mansione" />}
                        >
                          {mansioni?.map((mansione) => (
                            <MenuItem key={mansione.ID} value={mansione.ID}>
                              {mansione.Descrizione}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText
                          style={{
                            display: validation.Mansione ? "" : "None",
                          }}
                        >
                          Mansione obbligatoria
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={4}
                      sx={{ display: "flex", alignSelf: "center" }}
                    >
                      <FormControl
                        //required
                        fullWidth
                        //error={validation.Mansione}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Mansione Sicurezza
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          value={lavoratore.MansioneSicurezza}
                          onChange={(e) => {
                            let l = { ...lavoratore };
                            l.MansioneSicurezza = e.target.value;
                            setLavoratore(l);
                          }}
                          input={<OutlinedInput label="Mansione Sicurezza" />}
                        >
                          {mansioniSicurezza?.map((mansione) => (
                            <MenuItem key={mansione.ID} value={mansione.ID}>
                              {mansione.Descrizione}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <FormHelperText
                          style={{ display: validation.Mansione ? "" : "None" }}
                        >
                          Mansione obbligatoria
                        </FormHelperText> */}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                          ampmInClock={false}
                          renderInput={(props) => (
                            <TextField
                              fullWidth
                              required
                              {...props}
                              error={validation.DataAssunzione}
                              helperText={
                                validation.DataAssunzione
                                  ? "Data assunzione obbligatoria"
                                  : ""
                              }
                            />
                          )}
                          label="Data Assunzione"
                          value={moment(lavoratore.DataAssunzione)}
                          onChange={(newValue) => {
                            let l = { ...lavoratore };
                            l.DataAssunzione = newValue
                              ? newValue.format("YYYY-MM-DD")
                              : null;
                            setLavoratore(l);
                            //setDal(newValue?newValue.format("YYYY-MM-DD"):null);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                          ampmInClock={false}
                          renderInput={(props) => (
                            <TextField fullWidth {...props} />
                          )}
                          label="Data Termine"
                          value={moment(lavoratore.DataTermine)}
                          onChange={(newValue) => {
                            let l = { ...lavoratore };
                            l.DataTermine = newValue
                              ? newValue.format("YYYY-MM-DD")
                              : null;
                            setLavoratore(l);
                            //setDal(newValue?newValue.format("YYYY-MM-DD"):null);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider
                        sx={{
                          color: "rgba(58, 53, 65, 0.6)",
                          marginTop: "5px",
                          marginBottom: "15px",
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} md={4}>
                      <TextField
                        error={validation.Nome}
                        required
                        value={lavoratore.Nome}
                        margin="dense"
                        name="Nome"
                        label="Nome"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        helperText={validation.Nome ? "Nome obbligatorio" : ""}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        error={validation.Cognome}
                        value={lavoratore.Cognome}
                        margin="dense"
                        name="Cognome"
                        label="Cognome"
                        type="text"
                        required
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        helperText={
                          validation.Cognome ? "Cognome obbligatorio" : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <FormControl fullWidth sx={{ marginTop: "8px" }}>
                        <InputLabel id="Sesso">Sesso</InputLabel>
                        <Select
                          label="Sesso"
                          labelId="Sesso"
                          id="demo-simple-select"
                          value={lavoratore.Sesso}
                          onChange={(e) => {
                            let l = { ...lavoratore };
                            l.Sesso = e.target.value;
                            setLavoratore(l);
                          }}
                        >
                          <MenuItem value={1}>Maschio</MenuItem>
                          <MenuItem value={2}>Femmina</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                          ampmInClock={false}
                          renderInput={(props) => (
                            <TextField
                              fullWidth
                              sx={{ marginTop: "8px" }}
                              {...props}
                            />
                          )}
                          label="Data Fatturazione Costi Gestione"
                          value={moment(lavoratore.DtaCostiGestione)}
                          onChange={(newValue) => {
                            let l = { ...lavoratore };
                            l.DtaCostiGestione = newValue
                              ? newValue.format("YYYY-MM-DD")
                              : null;
                            setLavoratore(l);
                            //setDal(newValue?newValue.format("YYYY-MM-DD"):null);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        value={lavoratore.Email}
                        margin="dense"
                        name="Email"
                        label="Email"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        value={lavoratore.Telefono}
                        margin="dense"
                        name="Telefono"
                        label="Telefono"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        value={lavoratore.CF}
                        margin="dense"
                        name="CF"
                        label="Codice Fiscale"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        inputProps={{ maxLength: 16 }}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        value={lavoratore.LuogoDiNascita}
                        margin="dense"
                        name="LuogoDiNascita"
                        label="Luogo di Nascita"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                          ampmInClock={false}
                          renderInput={(props) => (
                            <TextField
                              fullWidth
                              sx={{ marginTop: "8px" }}
                              {...props}
                            />
                          )}
                          label="Data Di Nascita"
                          value={moment(lavoratore.DataDiNascita)}
                          onChange={(newValue) => {
                            let l = { ...lavoratore };
                            l.DataDiNascita = newValue
                              ? newValue.format("YYYY-MM-DD")
                              : null;
                            setLavoratore(l);
                            //setDal(newValue?newValue.format("YYYY-MM-DD"):null);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={6} md={4}>
                      {/*}
                  <Autocomplete
                    id="combo-box-demo"
                    options={clienti}
                    value={clienteDistaccato}
                    fullWidth
                    getOptionLabel={(option) => option.RagioneSociale}
                  
                    onChange={(event, newValue) => {
                      let l = { ...lavoratore };
                      setClienteDistaccato(newValue)
                      if (newValue) {
                        l.Distaccato = newValue.ID;
                    
                      
                      } else {
                        l.Distaccato = null;
                      }
                      setLavoratore(l);
                      //handleChangeCliente(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Distaccato In"
                        
                      />
                    )}
                  /> */}

                      <TextField
                        value={lavoratore.Distaccato}
                        margin="dense"
                        name="Distaccato"
                        label="Distaccato"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        value={lavoratore.GPCSC}
                        margin="dense"
                        name="GPCSC"
                        label="Cartella Sanitaria Cessati"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        value={lavoratore.GPCSCspesevive}
                        margin="dense"
                        name="GPCSCspesevive"
                        label="Cartella Sanitaria Cessati (Spese Vive)"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        value={lavoratore.DescrizioneMalattiaProfessionale}
                        margin="dense"
                        name="DescrizioneMalattiaProfessionale"
                        label="Descrizione Malattia Professionale"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <TextField
                        //rows={3}
                        value={lavoratore.Note}
                        margin="dense"
                        name="Note"
                        label="Note"
                        multiline
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      {lavoratore.IDDistacco ? (
                        <ConcludiDistacco
                          loadLavoratori={props.loadLavoratori}
                          handleCloseFather={handleClose}
                          lavoratore={props.lavoratore}
                          setSnack={setSnack}
                          setOpenSnack={setOpenSnack}
                        />
                      ) : (
                        <Distacca
                          clienti={clienti}
                          loadLavoratori={props.loadLavoratori}
                          handleCloseFather={handleClose}
                          lavoratore={props.lavoratore}
                          setSnack={setSnack}
                          setOpenSnack={setOpenSnack}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="StoricoGPCSC"
                            checked={
                              lavoratore.StoricoGPCSC === 1 ? true : false
                            }
                            onChange={(e) => {
                              let l = { ...lavoratore };
                              l.StoricoGPCSC = e.target.checked ? 1 : 0;
                              setLavoratore(l);
                            }}
                          />
                        }
                        label="Storico Gestione Cartella Sanitaria Cessati"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            name="MalattiaProfessionale"
                            checked={
                              lavoratore.MalattiaProfessionale === 1
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              let l = { ...lavoratore };
                              l.MalattiaProfessionale = e.target.checked
                                ? 1
                                : 0;
                              setLavoratore(l);
                            }}
                          />
                        }
                        label="Malattia Professionale"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Somministrato"
                            checked={
                              lavoratore.Somministrato === 1 ? true : false
                            }
                            onChange={(e) => {
                              let l = { ...lavoratore };
                              l.Somministrato = e.target.checked ? 1 : 0;
                              setLavoratore(l);
                            }}
                          />
                        }
                        label="Somministrato"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Alert"
                            checked={lavoratore.Alert === 1 ? true : false}
                            onChange={(e) => {
                              let l = { ...lavoratore };
                              l.Alert = e.target.checked ? 1 : 0;
                              setLavoratore(l);
                            }}
                          />
                        }
                        label="Includi nell'Alert"
                      />
                    </Grid>

                    <Grid
                      xs={12}
                      item
                      sx={{
                        display: "flex",
                        my: 2,
                        justifyContent: "space-between",
                      }}
                      fullWidth
                    >
                      <Button
                        variant="contained"
                        className="ButtonMiller"
                        startIcon={<Close />}
                        onClick={() => handleClose()}
                      >
                        Chiudi
                      </Button>
                      <Button
                        variant="contained"
                        className="ButtonMiller"
                        startIcon={<Check />}
                        onClick={handleSave}
                      >
                        Salva
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box sx={{ maxHeight: "60vh" }}>
                <ProtocolloSanitarioLavoratore
                  lavoratore={props.lavoratore}
                  idAzienda={props.lavoratore.Cliente}
                />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Box sx={{ maxHeight: "60vh" }}>
                <VisiteMedicheLavoratore
                  lavoratore={props.lavoratore.ID}
                  datiLavoratore={props.lavoratore}
                  cliente={props.lavoratore.Cliente}
                />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Box sx={{ maxHeight: "60vh" }}>
                <CorsiLavoratore
                  idLavoratore={props.lavoratore.ID}
                  idAzienda={props.lavoratore.Cliente}
                />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Box sx={{ maxHeight: "60vh" }}>
                <Allegati
                  idLavoratore={props.lavoratore.ID}
                  id={props.lavoratore.ID}
                  colonna="Lavoratore"
                  nomeLav={lavoratoreCopy.Nome + " " + lavoratoreCopy.Cognome}
                />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Box sx={{ maxHeight: "60vh" }}>
                <AddestramentoLavoratori
                  idLavoratore={props.lavoratore.ID}
                  lavoratore={props.lavoratore}
                  cliente={props.dati}
                />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={6}>
              <StampaDPI lavoratore={props.lavoratore} cliente={props.dati} />
            </TabPanel>
          </DialogContent>
        </Grid>
      </Dialog>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Distacca = ({
  clienti,
  lavoratore,
  setSnack,
  setOpenSnack,
  handleCloseFather,
  loadLavoratori,
}) => {
  const [open, setOpen] = useState(false);
  const [sedi, setSedi] = useState([]);
  const [mansioni, setMansioni] = useState([]);
  const [mansioniSicurezza, setMansioniSicurezza] = useState([]);
  const [dettaglioMedicina, setDettaglioMedicina] = useState([]);
  const [distaccato, setDistaccato] = useState({
    DistaccatoIn: null,
    Percentuale: 100,
    DataDistaccamento: new Date(),
    MansioneIn: 0,
    SedeIn: 0,
    DettaglioMedicinaIn: 0,
    MansioneSicurezzaIn: 0,
  });

  const handleClickOpen = () => {
    setDistaccato({
      DistaccatoIn: null,
      Percentuale: 100,
      DataDistaccamento: new Date(),
      MansioneIn: null,
      SedeIn: null,
      DettagliomedicinaIn: null,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadSedi = async (cliente) => {
    let responseSedi = await unsplash.get("sedi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: cliente },
    });
    const SediSenzaCantiere = responseSedi.data.data.filter(
      (elm) => elm.Cantiere === 0
    );
    setSedi(SediSenzaCantiere);
  };

  const loadMansioni = async (cliente) => {
    let responseMansioni = await unsplash.get("mansioni", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: cliente },
    });

    setMansioni(responseMansioni.data.data);
  };

  const loadMansioniSicurezza = async (cliente, sede) => {
    let responseMansioniSicurezza = await unsplash.get("mansioniSicurezza", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: cliente, sede: sede },
    });
    setMansioniSicurezza(responseMansioniSicurezza.data.data);
  };

  const loadDettaglioMedicina = async (sede) => {
    let responseDettaglioMedicina = await unsplash.get("dettaglioMedicina", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: sede },
    });

    setDettaglioMedicina(responseDettaglioMedicina.data.data);
  };

  const handleSave = async () => {
    let d = { ...distaccato };

    d.DistaccatoDa = lavoratore.Cliente;
    d.MansioneDa = lavoratore.Mansione;
    d.MansioneSicurezzaDa = lavoratore.MansioneSicurezza;
    d.SedeDa = lavoratore.Sede;
    d.DettaglioMedicinaDa = lavoratore.DettaglioMedicina;

    d.Lavoratore = lavoratore.ID;

    setDistaccato(d);

    try {
      let response = await unsplash.post(
        "distacco",
        { distacco: d },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      let idDistacco = response.data.message.insertId;
      let l = { ...lavoratore };
      l.Cliente = d.DistaccatoIn;
      l.Sede = d.SedeIn;
      l.Mansione = d.MansioneIn;
      l.MansioneSicurezza = d.MansioneSicurezzaIn;
      l.DistaccatoDa = d.DistaccatoDa;
      l.DettaglioMedicina = d.DettaglioMedicinaIn;
      l.IDDistacco = idDistacco;
      let responseLavoratori = await unsplash.put(
        "lavoratori",
        { lavoratore: l },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      /*  await unsplash.post(
      "storico",
      {
        tabella: "Lavoratori",
        key: lavoratore.ID,
        utente: sessionStorage.getItem("username"),
        after: lavoratore,
        before: lavoratoreCopy,
        operazione: "Modifica",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    ); */

      setSnack({
        severity: "success",
        messaggio: "Lavoratore modificato con successo",
      });
      setOpenSnack(true);
      handleCloseFather();
      loadLavoratori();
    } catch (e) {
      setSnack({ severity: "error", messaggio: "Errore durante la creazione" });
      setOpenSnack(true);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        className="ButtonMiller"
        startIcon={<Flight />}
        onClick={handleClickOpen}
      >
        Distacca
      </Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">
          Procedura Distaccamento
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid item xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={clienti}
                fullWidth
                getOptionLabel={(option) => option.RagioneSociale}
                onChange={(event, newValue) => {
                  if (newValue) {
                    let d = { ...distaccato };
                    d.DistaccatoIn = newValue.ID;
                    setDistaccato(d);

                    loadSedi(newValue.ID);
                    loadMansioni(newValue.ID);
                  } else {
                    let d = { ...distaccato };
                    d.DistaccatoIn = null;
                    setDistaccato(d);
                  }
                }}
                /*onChange={(event, newValue) => {
                      let l = { ...lavoratore };
                      setClienteDistaccato(newValue)
                      if (newValue) {
                        l.Distaccato = newValue.ID;
                    
                      
                      } else {
                        l.Distaccato = null;
                      }
                      setLavoratore(l);
                      //handleChangeCliente(newValue);
                    }} */
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Distaccato In" />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-name-label">Sede</InputLabel>
                <Select
                  // value={lavoratore.Sede}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  onChange={(e) => {
                    let d = { ...distaccato };
                    d.SedeIn = e.target.value;
                    setDistaccato(d);
                    loadDettaglioMedicina(e.target.value);
                    loadMansioniSicurezza(d.DistaccatoIn, e.target.value);
                  }}
                  input={<OutlinedInput label="Sede" />}
                >
                  {sedi?.map((sede) => (
                    <MenuItem key={sede.ID} value={sede.ID}>
                      {sede.Descrizione}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-name-label">
                  Dettaglio Medicina
                </InputLabel>
                <Select
                  //value={lavoratore.DettaglioMedicina}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  onChange={(e) => {
                    let d = { ...distaccato };
                    d.DettaglioMedicinaIn = e.target.value;
                    setDistaccato(d);
                  }}
                  input={<OutlinedInput label="Dettaglio Medicina" />}
                >
                  {dettaglioMedicina?.map((d) => (
                    <MenuItem key={d.ID} value={d.ID}>
                      {d.Blocco + " - " + d.Categoria + " - " + d.NomeMedico}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl required fullWidth>
                <InputLabel id="demo-multiple-name-label">Mansione</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  onChange={(e) => {
                    let d = { ...distaccato };
                    d.MansioneIn = e.target.value;
                    setDistaccato(d);
                  }}
                  input={<OutlinedInput label="Mansione" />}
                >
                  {mansioni?.map((mansione) => (
                    <MenuItem key={mansione.ID} value={mansione.ID}>
                      {mansione.Descrizione}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-name-label">
                  Mansione Sicurezza
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  onChange={(e) => {
                    let d = { ...distaccato };
                    d.MansioneSicurezzaIn = e.target.value;
                    setDistaccato(d);
                  }}
                  input={<OutlinedInput label="Mansione Sicurezza" />}
                >
                  {mansioniSicurezza?.map((mansione) => (
                    <MenuItem key={mansione.ID} value={mansione.ID}>
                      {mansione.Descrizione}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                //rows={3}
                value={dettaglioMedicina.Percentuale}
                margin="dense"
                name="Percentuale"
                label="Percentuale"
                type="number"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  let d = { ...distaccato };
                  d.Percentuale = e.target.value;
                  setDistaccato(d);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  ampmInClock={false}
                  renderInput={(props) => (
                    <TextField fullWidth sx={{ marginTop: "8px" }} {...props} />
                  )}
                  label="Data Distaccamento"
                  value={moment(distaccato.DataDistaccamento)}
                  onChange={(newValue) => {
                    let d = { ...distaccato };
                    d.DataDistaccamento = newValue
                      ? newValue.format("YYYY-MM-DD")
                      : null;
                    setDistaccato(d);
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className="ButtonMiller"
            onClick={handleClose}
          >
            annulla
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            onClick={handleSave}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ConcludiDistacco = ({
  distacco,
  lavoratore,
  setSnack,
  setOpenSnack,
  handleCloseFather,
  loadLavoratori,
}) => {
  const [open, setOpen] = useState(false);
  const [distaccato, setDistaccato] = useState({
    DataFineDistaccamento: new Date(),
  });

  const handleClickOpen = async () => {
    let response = await unsplash.get("distacco", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { distacco: lavoratore.IDDistacco },
    });
    let d = response.data.data ? response.data.data[0] : {};

    d.DataFineDistaccamento = new Date();

    setDistaccato(d);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    let d = { ...distaccato };

    d.ID = lavoratore.IDDistacco;
    setDistaccato(d);

    try {
      let response = await unsplash.put(
        "distacco",
        { distacco: d },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      let l = { ...lavoratore };
      l.Cliente = d.DistaccatoDa;
      l.Sede = d.SedeDa;
      l.Mansione = d.MansioneDa;
      l.DistaccatoDa = null;
      l.DettaglioMedicina = d.DettaglioMedicinaDa;
      l.MansioneSicurezza = d.MansioneSicurezzaDa;
      l.IDDistacco = null;
      let responseLavoratori = await unsplash.put(
        "lavoratori",
        { lavoratore: l },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      /*  await unsplash.post(
      "storico",
      {
        tabella: "Lavoratori",
        key: lavoratore.ID,
        utente: sessionStorage.getItem("username"),
        after: lavoratore,
        before: lavoratoreCopy,
        operazione: "Modifica",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    ); */

      setSnack({
        severity: "success",
        messaggio: "Lavoratore modificato con successo",
      });
      setOpenSnack(true);
      handleCloseFather();
      loadLavoratori();
    } catch {
      setSnack({ severity: "error", messaggio: "Errore durante la creazione" });
      setOpenSnack(true);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        className="ButtonMiller"
        startIcon={<AirplanemodeInactive />}
        onClick={handleClickOpen}
      >
        Concludi Distacco
      </Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">
          Procedura Distaccamento
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  ampmInClock={false}
                  renderInput={(props) => (
                    <TextField fullWidth sx={{ marginTop: "8px" }} {...props} />
                  )}
                  label="Data Distaccamento"
                  // value={lavoratore.DataDiNascita}
                  onChange={(newValue) => {
                    let d = { ...distaccato };
                    d.DataFineDistaccamento = newValue
                      ? newValue.format("YYYY-MM-DD")
                      : null;
                    setDistaccato(d);
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className="ButtonMiller"
            onClick={handleClose}
          >
            annulla
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            onClick={handleSave}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DettaglioLavoratore;
